@use '../config/' as *;
@forward '../main-scss/';
@forward './commons';
@forward './stats-nav';
@forward './table';
.waf-statslisting {
    padding: 0 var(--space-1);
    .table {
        display: block;
    }
    .waf-head {
        padding-right: 12rem;
        @extend %flex-sb-n;
    }
    .waf-body {
        padding-top: 3rem;
        @extend %px-3;
        .active {
            background: linear-gradient(180deg, var(--club-primary) 0%, var(--club-secondary) 96.04%);
            @extend %flex-wrap;
            .waf-accordion-head {
                @extend %white;
                .text {
                    @extend %white;
                }
                .table-data {
                    width: 5rem;
                    justify-content: center;
                    .text {
                        @extend %font-18-pb;
                    }
                    &-position {
                        .text {
                            @include textstroke();
                            @extend %font-26;
                            @extend %club-secondary-5;
                        }
                    }
                    &-club {
                        justify-content: flex-start;
                        width: 13rem;
                    }
                    &-more {
                        align-self: flex-start;
                    }
                }
            }
            .waf-accordion-panel {
                @extend %flex-n-fs;
            }
            .table-data {
                &-club {
                    height: 10rem;
                    padding-left: 0;
                }
                &-more {
                    .btn-more {
                        &::before {
                            transform: rotate(180deg);
                            @extend %white;
                        }
                    }
                }
                &-position {
                    flex-shrink: 1;
                    align-self: flex-start;
                }
                &-player,
                &-name {
                    flex: 1;
                }
            }
            .club {
                &-img {
                    width: 4rem;
                    height: 4rem;
                    @extend %p-1;
                }
                &-name {
                    .name {
                        @extend %font-12;
                    }
                    .short-name {
                        display: none;
                    }
                    .full-name {
                        display: block;
                        text-align: left;
                        &::first-line {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
    .table {
        &.stats-table {
            @extend %mt-6;
        }
        &-data {
            width: 5rem;
            justify-content: center;
            &-more {
                flex-basis: 3rem;
                flex-grow: 0;
                flex-shrink: 0;
                @extend %p-0;
                .btn-more,
                .text {
                    font-size: 0;
                }
            }
            &-club {
                flex: 1;
                justify-content: flex-start;
            }
        }
        &-body {
            .table-data-more {
                .btn-more {
                    @extend %flex-c-c;
                    &::before {
                        transition: transform 300ms;
                        transform-origin: center;
                        @include icon(chevron-down, 16)
                    }
                }
            }
        }
    }
    .waf-accordion-head {
        @extend %w-100;
    }
    .club-wrap {
        @extend %gap-1;
    }
    .club {
        &-img {
            width: 3rem;
            height: 3rem;
            flex-shrink: 0;
            @extend %white-bg;
            @extend %circle-radius;
        }
        &-wrap {
            @extend %flex-n-c;
            @extend %gap-1;
        }
        &-name {
            @extend %font-8-pr;
            @extend %mb-0;
            &:hover {
                text-decoration: underline;
            }
            .short-name {
                @extend %d-none;
            }
        }
        &-inner-stats {
            background-image: linear-gradient(to bottom, hsl(var(--hsl-white)/0.3), hsl(var(--hsl-white)/0));
            .table-head {
                .text {
                    @extend %uppercase;
                }
            }
            .table-row {
                &:not(:last-child) {
                    border-color: hsl(var(--hsl-white)/0.5)
                }
            }
            .table-data {
                &-goals,
                &-games-played {
                    .text {
                        @extend %white;
                        @extend %font-14-pr;
                    }
                }
                &-player,
                &-name {
                    width: calc(100% - 10.5rem);
                    justify-content: flex-start;
                    &.table-data {
                        flex: unset;
                    }
                    .text {
                        line-height: 1;
                        @extend %font-12;
                        @extend %text-left;
                    }
                }
                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .waf-accordion-panel {
        @extend %w-100;
        @extend %relative;
        @extend %px-3;
        @extend %d-none;
        @extend %flex-wrap;
        > .table-row {
            flex-wrap: wrap;
        }
        .club-wrap {
            @extend %d-none;
        }
        .table {
            @extend %w-100;
            &-head {
                @extend %d-none;
            }
            &-row {
                flex-wrap: nowrap;
            }
        }
        .text {
            @extend %white;
        }
        .club-position {
            @extend %d-none;
        }
    }
}
@include mq(col-md) {
    .waf-statslisting {
        --card-height: 21rem;
        .table-head {
            .player {
                margin-left: 2.5rem;
            }
        }
        .waf-body {
            padding-top: 0;
            padding-inline: 0;
            margin-inline: 0;
        }
        .club-name {
            display: block;
        }
        .table-data {
            flex: 0 0 13rem;
            &-club {
                flex: 1;
            }
            &-more {
                flex: 0 0 3rem;
                margin-right: 3rem;
            }
        }
        .waf-accordion-panel {
            margin-right: 3rem;
        }
        .club-inner-stats {
            .table-head {
                display: block;
                background-color: transparent;
                .text {
                    font-size: 3.2rem;
                    font-weight: 700;
                }
                .table-data {
                    height: unset;
                    background-color: transparent;
                }
            }
            .table-data-name {
                text-align: left;
            }
            .table-data-player,
            .table-data-name {
                width: calc(100% - 26.6rem);
            }
        }
        .waf-body {
            .active {
                flex-wrap: nowrap;
                padding-block: var(--space-12) var(--space-6);
                position: relative;
                align-items: stretch;
                .waf-accordion-head {
                    .table-data-position {
                        .text {
                            font-size: 6rem;
                        }
                    }
                }
                .table-row-head {
                    height: 100%;
                }
                .table-data {
                    &-more {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 4rem;
                    }
                    &-club {
                        height: 20rem;
                    }
                }
                .waf-accordion-head {
                    width: 45rem;
                    .table-data {
                        display: none;
                        &-position,
                        &-club,
                        &-more {
                            display: flex;
                        }
                    }
                }
                .club {
                    &-name {
                        display: none;
                    }
                    &-img {
                        width: 16rem;
                        height: 16rem;
                        aspect-ratio: 1 / 1;
                        padding: 2rem;
                    }
                }
                .waf-accordion-panel {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                }
            }
        }
    }
}